html {
  box-sizing: border-box;
  background-color: darkgreen;
}

@font-face {
  font-family: 'MidnightMinutes';
  src: local('MidnightMinutes'), url("./fonts/MidnightMinutes.ttf") format('truetype');
}

h1 {
  color: white;
  font-family: 'MidnightMinutes', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

p {
  color: white;
  font-family: 'MidnightMinutes', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.app {
  width: 960px;
  margin: 0 auto;
  padding: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  background-color: indigo;
  margin-bottom: 20px;
  border-style: double;
  border-color: orange;
}

nav ul li {
  padding: 20px;
}

nav ul li a {
  color: orange;
  text-decoration: none;
  font-family: 'MidnightMinutes', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.current {
  border-bottom: 4px solid orange;
}

h1 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
}

.main {
  background-color: rgba(0, 0, 0, 0.2);
}
